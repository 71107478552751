import React, { Component } from "react";
import { connect } from 'react-redux';

// import { getAllSubjects } from '../../constants/commonFunction';
// import loadingActions from "../../redux/actions";
import "./syllabus.css";

const Stopics = (props) => {
  const exam = props?.location?.state?.exam;
  const subjectId = props?.location?.state?.subject_id;
  // console.log();
  // console.log(exam, props?.location?.state?.subject_id);
  // console.log();
  const stopics = props?.subjectTopicHierarchy[exam]?.find(s => s.subject_id == subjectId );
  console.log(stopics);

  return (
    <main className="container flex-grow">
        <div className="flex">
          <div className="flex-three">
            <h2>{stopics.subject_name}</h2>
              <div className="sub-wrapper flex-wrap flex-space-between flex-column-mobile">
                <div className="bordered margin-10-0 large-shadow padding-5-10 bg-stripes text-align-center">
                  {
                    stopics?.topics?.map(mt => <div key={mt.maintopic_id}>
                          <h2>{mt.name}</h2>
                          {
                            mt?.children?.map(c => <div key={c.maintopic_id} className="margin-10-0">
                                <h3 className="bg-primary rounded padding-10 color-white">{c.name}</h3>
                                  <div className="column-count-2 left-text">
                                    {c?.children?.map(cc =>
                                      <ul key={cc.maintopic_id}>
                                          <h4 className="display-inline-block margin-10-0">{cc.name}</h4>
                                        {
                                          cc?.children?.map(ccc => 
                                            <li 
                                              onClick={() => props.history.push({pathname:'/topicDetails', search: `?id=${ccc.maintopic_id}` })} 
                                              className="cursor-pointer"
                                              key={ccc.maintopic_id}
                                            >{ccc.name}</li>
                                            )
                                        }
                                    </ul>)
                                    }
                                  </div>
                              </div>)
                          }
                        </div>
                      )
                  }
            </div>
            </div>
          </div>
          <div className="flex-one">
          <div className="bordered margin-10-0 large-shadow padding-5-10 bg-stripes text-align-center">
            <h3>Sidebar</h3>
          </div>
          </div>
        </div>
      </main>
  );
}

const mapStateToProps = (state) => ({
  subjectTopicHierarchy: state.utilsReducer.subjectHierarchy || [],
});

export default connect(mapStateToProps) (Stopics);

// export default Stopics;
