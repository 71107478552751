const { createLogger, format, transports } = require('winston');

// const env = process.env.NODE_ENV || 'development';
const { combine, timestamp, colorize, printf, align } = format;
// const url = 'https://jsonplaceholder.typicode.com/posts';
// const headers = {
//   'Content-type': 'application/json; charset=UTF-8',
// };
const all = format(info => {
  // const data = {
  //   timestamp: info.timestamp,
  //   level: info.level,
  //   message: info.message,
  //   label: info.label,
  // };
  // PostRequest(url, headers, data).then((response) => {
  //     console.log("response", response.data);
  // });
  return info;
});

const logger = createLogger({
  // change level if in dev environment versus production
  level: 'debug', // 'info',
  format: combine(
    timestamp(),
    all(),
    colorize(),
    align(),
    printf(
      info =>
        `${info.timestamp} [${info.label}] ${info.level}: ${info.message}`,
    ),
  ),
  transports: [
    new transports.Console({
      level: 'info',
      format: format.combine(
        format.colorize(),
        format.printf(
          info => `${info.timestamp} ${info.level}: ${info.message}`,
        ),
      ),
    }),
  ],
});

export default logger;
