import React, { Component } from "react";
import { connect } from 'react-redux';

import loadingActions from "../../redux/actions";
import { register } from '../../constants/commonFunction';

import "./registration.css";
class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname:"",
      lastname:"",
      mobile:"",
      gender:"",
      dob:"",
      email:"",
      full_address:"",
      type:"guest",
      password:"",
      password_confirm:"",
      error:"",
      loginErrors:{}
    };
  }

  
  componentDidMount() {
    const { isLoggedIn, history } = this.props;
    if(isLoggedIn) {
      history.replace('/profile')
    }
  }

//   {
//     "firstname":"sss",
//     "lastname":"kkk",
//     "mobile":"9999999999",
//     "email":"aaa@aa.aa",
//     "gender":"Male",
//     "dob":"18/02/1990",
//     "full_address":"optional",
//     "type":"guest",
//     "password":"asdfghjkl",
//     "password_confirm":"asdfghjkl"
// }

 setValues(e){
    const key = Object.keys(e)[0];
    const value = e[key];
    this.setState({[key]:value});
  }

  register(e){
    e.preventDefault();
    const { firstname, lastname, mobile, gender, dob, email, full_address, type, password, password_confirm } = this.state;

    console.log(password, password_confirm);
    let error;
    if(password != password_confirm){
      error = 'Pasword mismatch';
      this.setState({error});
      return false;
    }else{
      error = '';
      this.setState({error});
    }

    let payload = {
        firstname,
        lastname,
        mobile,
        gender,
        dob,
        email,
        full_address,
        type:"guest",
        password,
        password_confirm
      };

    register(payload).then(res => {
      if(res?.data?.status == 422){
        this.setState({loginErrors:res?.data?.message});
      }else{
        const { history } = this.props;
        // loadingActions.loginUser(res.data);
        history.push({pathname:'/login' });
      }
    }).catch()
  }

  renderErrors(errObj){
    let err = '';
    for (let key in errObj) {
      if (errObj.hasOwnProperty(key)) {
        err += '<p>'+errObj[key]+'</p>';
      }
  }
  return err;
  }

  render() {
    const { error, loginErrors } = this.state;
    console.log('loginErrors',loginErrors);
    return (
      <main className="container flex-grow">
        <div className="container">
          <div className="flex flex-space-between flex-column-mobile flex-gap-32">
            <div>
              <h1>Get a free account</h1>

              <p className="larger sub">
                Lorem ipsum 
              </p>
            </div>

            <div style={{minWidth: '60%'}}>
              <form className="card card-medium">
                <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>

                  <label>First Name*</label>
                  <input
                    type="text"
                    onChange={(e)=>this.setValues({firstname:e.target.value})}
                    />
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>Last Name*</label>
                  <input
                    type="text"
                    onChange={(e)=>this.setValues({lastname:e.target.value})}
                    />
                    </div>
                </div>

                <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>

                  <label>Mobile*</label>
                  <input
                    type="number"
                    onChange={(e)=>this.setValues({mobile:e.target.value})}
                  />
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>Email*</label>
                  <input
                    type="email"
                    onChange={(e)=>this.setValues({email:e.target.value})}
                  />
                    </div>
                </div>

                <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>
                  <label>Gender*</label>
                  <select onChange={(e)=>this.setValues({gender:e.target.value})}>
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>DOB*</label>
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    onChange={(e)=>this.setValues({dob:e.target.value})}
                    />
                    </div>
                </div>

                <div className="form-field">
                  <label>Full Address</label>
                  <input
                    type="text"
                    onChange={(e)=>this.setValues({full_address:e.target.value})}
                  />
                </div>

                <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>
                  <label>Password*</label>
                    <input
                      type="password"
                      onChange={(e)=>this.setValues({password:e.target.value})}
                    />
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>Confirm Password*</label>
                  <input
                    type="password"
                    onChange={(e)=>this.setValues({password_confirm:e.target.value})}
                    />
                    </div>
                </div>
                <p className="red">{error}</p>
                <p className="red" dangerouslySetInnerHTML={{__html:this.renderErrors(loginErrors)}}></p>
                <button className="button-primary" onClick={(e)=>this.register(e)}>
                  <i className="icon-user"></i>
                  Register
                </button>
              </form>

              <p className="larger" style={{ margin: "1.7rem 0 1rem" }}></p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Registration;
