import React, { Component } from "react";
import { createBrowserHistory }  from 'history';

import logger from './logger/logger';
import RoutingHolder from "./routingHolder";
import Header from './component/header/header';
import Footer from "./component/footer/footer";
import loadingActions from "./redux/actions/utilsAction";

const history = createBrowserHistory();
class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      changedRoute:false
    }
  }

  
componentDidMount(){
  // loadingActions.loginUser({user_id:125, email:'sushilkawad@gmail.com'});
  logger.info('Information message');
  logger.warn('Warning message');
  logger.debug('Debugging info');
}

// componentWillUpdate(){  
//   console.log('222')
// }

// shouldComponentUpdate(){  
//   console.log('ssss')
// }

// componentWillReceiveProps(){  
//   console.log('333')
// }

// static getDerivedStateFromProps(){
//   console.log('444')
// }
  
  // shouldComponentUpdate(props,state) {
    // console.log(state);
    // console.log(props);
    // console.log(this.state);
    // console.log(this.props);
    // if(state.changedRoute != this.state.changedRoute){
    //   return true;
    // }
    // return false;
  // }

  render() {
    const { changedRoute } = this.state;
    console.log('enter in app render');
    return ( 
      <div>
        {/* <Header history={history} routeChanged={changedRoute} /> */}
        <RoutingHolder history={history} />
        <Footer history={history} />
      </div>
    )
  }
}

export default App;
