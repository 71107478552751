import React, { Component } from "react";
import { connect } from 'react-redux';

import { getAllProgress, removeThisProgress } from '../../constants/commonFunction';
import loadingActions from "../../redux/actions";
import "./progress.css";
// import logo from '../../logo.svg';
// import SearchTopic from '../searchTopics/searchTopic';
// import Header from "../../component/header/header";
// import Footer from "../../component/footer/footer";
// import { getTopicList } from "../../constants/commonFunction";

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // topicList: [],
    };
  }

  componentDidMount() {
    const { userInfo } = this.props;
    // console.log('userInfo:', userInfo);
    getAllProgress(userInfo.user_id).then((response) => {   
      // console.log(response); 
      loadingActions.setAllProgress(response.data);
    })
    .catch((error) => {
      console.log('Error:',error);
    });
  }

  removeProgress(bid){
    removeThisProgress(bid).then((response) => {   
      // console.log(response); 
      loadingActions.removeThisProgress(response.data);
    })
    .catch((error) => {
          console.log('Error:',error);
    });
  }

  goToThis(id, key){
    const { history } = this.props;
    let hashtag = key.split('_')[0];
    if(hashtag == 'content'){
      hashtag = 'top';
    }
    history.push({pathname:'/topicDetails', search: `?id=${id}#${hashtag}` });
  }

  render() {
    // const  { topicList } = this.state;

    const { allProgress } = this.props;
    // console.log('topicHierarchy',topicHierarchy);


    return (
      <main className="container flex-grow">
      <div className="container">
                   <div className="flex flex-space-between flex-column-mobile flex-gap-32">
          <div>
            <h1>My Progress</h1>
            <p className="larger sub">
              {/* Lorem ipsum  */}
            </p>
          </div>

          <div style={{minWidth: '60%'}}>
              {
                allProgress.map((b,i) => <div key={i} className="card card-medium">
                  <h2 onClick={() => this.goToThis(b.um_maintopic_id, b.um_key)} className="curson-pointer">{b.title}</h2>
                  <div>
                    <span>{b.created_at}</span> | <span onClick={() => this.removeProgress(b.um_id)} className="curson-pointer red">Delete</span>
                  </div>
                </div>)
              }
          </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
  allProgress: state.utilsReducer.allProgress || [],
});

export default connect(mapStateToProps) (Progress);
