import React, { Component } from 'react';
// import { baseURL, apiBaseURL } from "../../config/config";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import loadingActions from "../../redux/actions";
import './header.css';

class Header extends Component {
    constructor(props){
        super(props);
        this.state={
          routeChanged:false,
          showDetailsHeader:false
        }
    }

    // thisOnChange = (e) => {
    //   console.log(e);
    // }

    // goToHome = () => {
    //   console.log('enter in go to home')
    //   const { history } = this.props;
    //   history.push('/');
    // }

    //asdf
    // shouldComponentUpdate(props, state){
      // if(state.changedRoute != this.state.changedRoute || state.showDetailsHeader != this.state.showDetailsHeader){
      //   return true;
      // }
      // return false;
  // }
    
    // componentDidMount(){  
    //     console.log('ccc')
    // }
    
    // componentWillReceiveProps(){  
    //     console.log('aaa')
    //     console.log('aaa',this.props);
    // }
    
    static getDerivedStateFromProps(props){
      console.log('header getDerivedStateFromProps',props)
      if(props.routeChanged == 'topicDetails'){
        return {showDetailsHeader:true};
      }
      return {showDetailsHeader:false};
    }

    render() {
      const { history, userInfo, isLoggedIn } = this.props;
      const { showDetailsHeader } = this.state;
      // console.log('rrrr',this.state);
      // console.log('isLoggedIn',isLoggedIn);
      return (
        <div className="header">
          <nav className="u-full-width no-print">
            <div className="top-nav-holder">
              <div className="container">
                <div className="layer-holder top-navigation">
                  <div className="layer flex flex-space-between" style={{alignItems: 'center', height: 50}}>
                    <div className="flex" style={{alignItems: 'center'}}>
                      <a href="index.html" className="logo-holder">
                          <img alt="Screener Logo" className="logo" src="static/img/logo-black.f44abb4998d1.svg" />
                      </a>

                      <div className="flex flex-gap-8 show-from-desktop" style={{margin: 4}}>
                      <div className="show-from-desktop desktop-links" style={{marginLeft: 48}}>
                        <Link to="/"> Home </Link>
                        <Link to="/topics"> Topics </Link>
                        <div className="upper syllabus-dd dropdown-menu">
                          Syllabus
                          <i className="icon-down"></i>
                         <div className="dropdown-content flex-column">
                          <Link to="/syllabus/pre"> 
                                Pre
                          </Link>
                          <Link to="/syllabus/mains">
                                Mains
                          </Link>
                          <Link to="/syllabus/interview">
                                Interview
                          </Link>
                        </div>
                        </div>
                      </div>


                      </div>
                    </div>

                    <div className="hide-on-mobile flex flex-gap-16" style={{justifyContent: 'flex-end', flex: '1 1 400px', marginLeft: 48}}>
                     
                      <div className="flex flex-gap-8 show-from-desktop" style={{margin: 4}}>
                      {!isLoggedIn && <>
                      <Link className="button account" to="/login"> <i className="icon-user-line blue-icon"></i> Login </Link>
                      <Link className="button account button-secondary" to="/registration"> Registration </Link>
                      </>}

                      {isLoggedIn &&
                      <div className="dropdown-menu show-from-desktop">
                        <button className="account">
                          <i className="icon-user-line blue-icon"></i>
                          {`${userInfo?.firstname}` }
                          <i className="icon-down"></i>
                        </button>

                         <div className="dropdown-content flex-column" id="nav-user-menu">
                          <Link to="/profile"> 
                                <i className="icon-user-line"></i>
                                Profile
                          </Link>
                          <Link to="/progress"> 
                                <i className="icon-results"></i>
                                Progress
                          </Link>
                          <Link to="/bookmarks">
                          <i className="icon-list"></i>
                                Bookmarks
                          </Link>
                          <Link to="/courses">
                          <i className="icon-document"></i>
                                courses
                          </Link>
                          <hr className="no-hover" />                      
                          <Link onClick={() => loadingActions.logoutUser()} to="/"> 
                          <i className="icon-off"></i>
                                Logout
                        </Link>
                        </div>
                      </div>}

                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-menu-bar hide-from-desktop flex flex-space-between">
                <Link to="/"> 
                      <i className="icon-screens"></i>
                      <br />  
                      Home </Link>
                <Link to="/topics"> 
                      <i className="icon-screens"></i>
                      <br />  
                      Topics </Link>
                  {/* <a href="explore/index.html">
                      <i className="icon-screens"></i>
                      <br />
                      Screens
                  </a> */}
                  {/* <button className="button-plain">
                      <i className="icon-tools"></i>
                      <br />
                      Tools
                  </button> */}

                  {!isLoggedIn && <>
                      <Link to="/login">
                        <i className="icon-user-line"></i> 
                      <br />
                      Login 
                      </Link>
                      <Link to="/registration"> 
                      <i className="icon-user-plus"></i>
                      <br />
                      Registration </Link>
                      </>}


                  {/* <a href="login/index.html">
                      <i className="icon-user-line"></i>
                      <br />
                      Login
                  </a> */}
                  {/* <a href="register/index.html">
                      <i className="icon-user-plus"></i>
                      <br />
                      Get account
                  </a> */}
                </div>
              </div>
            </div>

            {showDetailsHeader && <div className="sub-nav-holder">
              <div className="container">
              <div className="sub-nav-holder">
                    <div className="container">
                      
              <div className="sub-nav show-from-tablet-landscape">
                <a href="#top" className="shrink-text">Content</a>
                <a href="#currentAffairs">Current Affair</a>
                <a href="#statics">Statics</a>
                <a href="#quotes">Quotes</a>
                <a href="#diagrams">Diagrams</a>
                <a href="#videos">Videos</a>
                <a href="#questions">Questions</a>
                <a href="#conclusion">Conclusion</a>
              </div>
                    </div>
                  </div>
              </div>
            </div>}

          </nav>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
});

export default connect(mapStateToProps) (Header);