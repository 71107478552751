import React, { Component } from "react";
import { connect } from 'react-redux';

import { getAllTopics } from '../../constants/commonFunction';
import loadingActions from "../../redux/actions";
import { register } from '../../constants/commonFunction';
import "./profile.css";
// import logo from '../../logo.svg';
// import SearchTopic from '../searchTopics/searchTopic';
// import Header from "../../component/header/header";
// import Footer from "../../component/footer/footer";
// import { getTopicList } from "../../constants/commonFunction";

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname:"",
      lastname:"",
      mobile:"",
      gender:"",
      dob:"",
      email:"",
      full_address:"",
      type:"guest",
      password:"",
      password_confirm:"",
      message:"",
      loginErrors:{}
    };
  }

  componentDidMount() {
    // getAllTopics().then((response) => {    
    //   loadingActions.setAllTopicsHierarchy(response.data);
    // })
    // .catch((error) => {
    //   console.log('Error:',error);
    // });
  }


  
 setValues(e){
  const key = Object.keys(e)[0];
  const value = e[key];
  this.setState({[key]:value});
}

updateUser(e){
  e.preventDefault();
  const { firstname, lastname, mobile, gender, dob, email, full_address, type, password, password_confirm } = this.state;
  const { userInfo } = this.props;

  // let error;
  // if(password != password_confirm){
  //   error = 'Pasword mismatch';
  //   this.setState({error});
  //   return false;
  // }else{
  //   error = '';
  //   this.setState({error});
  // }

  let payload = {
      user_id: userInfo.user_id,
      firstname: firstname || userInfo.firstname ,
      lastname: lastname || userInfo.lastname,
      mobile: mobile || userInfo.mobile,
      gender: gender || userInfo.gender,
      dob: dob || userInfo.dob,
      // email,
      full_address: full_address || userInfo.full_address,
      // type:"guest",
      // password,
      // password_confirm
    };
  register(payload).then(res => {
      if(res?.data?.status == 422){
        this.setState({loginErrors:res?.data?.message});
      }else{
        this.setState({message:"Success"});
        const newUserInfo = {...userInfo, ...res.data.data};
        loadingActions.loginUser(newUserInfo);
      }
    }).catch()
  }

  renderErrors(errObj){
    let err = '';
    for (let key in errObj) {
      if (errObj.hasOwnProperty(key)) {
        err += '<p>'+errObj[key]+'</p>';
      }
  }
  return err;
  }

  render() {
    const { loginErrors, firstname, lastname, mobile, gender, dob, full_address, message } = this.state;
    const { userInfo } = this.props;
    // 18/02/1990 => 1990-02-18
    let pdob = userInfo.dob.split('/');
    let day = pdob[0];
    let month = pdob[1];
    let year = pdob[2];
    let fdate = year +'-'+ month +'-'+ day;
    console.log('userInfo', userInfo);
    return (
      <main className="container flex-grow">
        <div className="container">
          <div className="flex flex-space-between flex-column-mobile flex-gap-32">
            <div>
              <h1>My Profile</h1>
              <p className="larger sub">
                {userInfo?.email}
              </p>
            </div>

            <div style={{minWidth: '60%'}}>
              <form className="card card-medium">
                <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>

                  <label>First Name*</label>
                  <input
                    type="text"
                    value={firstname || userInfo.firstname}
                    onChange={(e)=>this.setValues({firstname:e.target.value})}
                    />
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>Last Name*</label>
                  <input
                    type="text"
                    value={lastname || userInfo.lastname}
                    onChange={(e)=>this.setValues({lastname:e.target.value})}
                    />
                    </div>
                </div>

                <div className="flex flex-column-mobile flex-gap-8 form-field">
                <div style={{flex:1}}>
                <label>Mobile*</label>
                <input
                  type="number"
                  value={mobile || userInfo.mobile}
                  onChange={(e)=>this.setValues({mobile:e.target.value})}
                  />
                  </div>
                  <div style={{flex:1}}>
                  <label>Gender*</label>
                  <select defaultValue={gender || userInfo.gender} onChange={(e)=>this.setValues({gender:e.target.value})}>
                    <option value="">Select gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>DOB*</label>
                  <input
                    type="date"
                    placeholder="mm/dd/yyyy"
                    value={dob || fdate}//{userInfo.dob} //2013-01-08
                    onChange={(e)=>this.setValues({dob:e.target.value})}
                    />
                    </div>
                </div>

                <div className="form-field">
                  <label>Full Address</label>
                  <input
                    type="text"
                    value={full_address || userInfo.full_address}
                    onChange={(e)=>this.setValues({full_address:e.target.value})}
                  />
                </div>

                {/* <div className="flex flex-column-mobile flex-gap-8 form-field">
                  <div style={{flex:1}}>
                  <label>Password*</label>
                    <input
                      type="password"
                      onChange={(e)=>this.setValues({password:e.target.value})}
                    />
                    </div>
                  <div style={{flex:1}}>
                    
                  <label>Confirm Password*</label>
                  <input
                    type="password"
                    onChange={(e)=>this.setValues({password_confirm:e.target.value})}
                    />
                    </div>
                </div> */}

                <p className="red" dangerouslySetInnerHTML={{__html:this.renderErrors(loginErrors)}}></p>
                <p className="green">{message}</p>
                <button className="button-primary" onClick={(e)=>this.updateUser(e)}>
                  <i className="icon-user"></i>
                  Update
                </button>
              </form>

              <p className="larger" style={{ margin: "1.7rem 0 1rem" }}></p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}


const mapStateToProps = (state) => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
});

export default connect(mapStateToProps) (Profile);
