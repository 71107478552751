import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import HomePage from "../pages/homePage";
import Login from "../pages/login";
import Registration from "../pages/registration";
import Topics from "../pages/topics";
import Syllabus from "../pages/syllabus";
import Stopics from "../pages/syllabus/stopics";
import TopicDetails from "../pages/topicDetails";
import Profile from "../pages/profile";
import Bookmarks from "../pages/bookmarks";
import Progress from "../pages/progress";
import Courses from "../pages/courses";

import Header from '../component/header';
// import Footer from "../component/footer";
import './routingHolder.css';

class RoutingHolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changedRoute:false
    }
  }

// componentDidMount(){
//   console.log('ccccc');
// }


// componentWillUpdate(){  
//   console.log('uuu')
// }

// componentWillReceiveProps(){  
//   console.log('nnnn')
// }

// static getDerivedStateFromProps(){

//   console.log('ggggg')
// }

routeChanged = (r) => {
//   this.setState({changedRoute:r});
}

// shouldComponentUpdate(props,state) {
//   if(state.changedRoute != this.state.changedRoute){
//     return true;
//   }
//   return false;
// }

shouldComponentUpdate(nextProps) {
  const { isLoggedIn } = this.props;
  if (isLoggedIn !== (nextProps || {}).isLoggedIn) {
    return true;
  }
  return false;
}

  render() {
    console.log('enter in react router',this.props)
    const { history, isLoggedIn } = this.props;
    const { changedRoute } = this.state;
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props => (
          isLoggedIn ? <Component {...props} /> :  <main className="container"><div>No access</div></main>
        )
        }
      />
    );

    return (
      <div className="routing-holder" >
        <BrowserRouter history={history} >
        <Header history={history} routeChanged={changedRoute} />
          <Switch>
            <Route
              path="/topicDetails"
              component={() => <TopicDetails history={history} />}
              // component={withRouter(TopicDetails)}
              />
              <Route
                path="/login"
                exact
                component={(p) => <Login {...p} />}
                // component={Login}
              />
              <Route
                path="/registration"
                exact
                component={(p) => <Registration {...p} />}
                // component={Login}
              />
              <PrivateRoute
                path="/profile"
                component={(p) => <Profile {...p} />}
                // component={Login}
              />
              <PrivateRoute
                path="/bookmarks"
                exact
                component={(p) => <Bookmarks {...p} />}
                // component={Login}
              />
              <PrivateRoute
                path="/progress"
                exact
                component={(p) => <Progress {...p} />}
                // component={Login}
              />
              <PrivateRoute
                path="/courses"
                exact
                component={(p) => <Courses {...p} />}
                // component={Login}
              />
              <Route
                path="/topics"
                exact
                component={(p) => <Topics {...p} />}
                // component={Login}
              />
                <Route
                  path="/syllabus/:ename/topics"
                  // exact
                  component={(p) => <Stopics {...p} />}
                  // component={Login}
                />
              <Route
                path="/syllabus/:ename"
                // exact
                component={(p) => <Syllabus {...p} />}
                // component={Login}
              />
            <Route
              path="/"
              exact
              // component={() => <HomePage history={history} />}
              component={HomePage}
            />
            {/* <Route path='/'>
              <HomePage history={history}/>
              </Route>
              <Route path='/topicDetails'>
              <TopicDetails history={history}/>
            </Route> */}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
});

// export default RoutingHolder;
export default connect(mapStateToProps)(RoutingHolder);
