import { bindActionCreators } from 'redux';
import { store } from '../store/store';

const loginUser = data => dispatch => {
    dispatch({
      type: 'SET_LOGIN',
      payload: data,
    });
};

const logoutUser = data => dispatch => {
    dispatch({
      type: 'SET_LOGOUT',
      payload: data,
    });
};

const setListOfTopics = data => dispatch => {
    dispatch({
      type: 'SET_TOPIC_LIST_SUCCESS',
      payload: data,
    });
};

const setAllTopicsHierarchy = data => dispatch => {
    dispatch({
      type: 'SET_TOPIC_HIERARCHY',
      payload: data,
    });
};

const setAllSubjectsHierarchy = data => dispatch => {
    dispatch({
      type: 'SET_SUBJECT_HIERARCHY',
      payload: data,
    });
};

const getTopicDetails = data => dispatch => {
    dispatch({
      type: 'GET_TOPIC_DETAILS_SUCCEESS',
      payload: data,
    });
};

const setAllBookmarks = data => dispatch => {
    dispatch({
      type: 'SET_ALL_BOOKMARKS',
      payload: data,
    });
};

const removeThisBookmarks = data => dispatch => {
    dispatch({
      type: 'REMOVE_THIS_BOOKMARK',
      payload: data,
    });
};

const setAllProgress = data => dispatch => {
    dispatch({
      type: 'SET_ALL_PROGRESS',
      payload: data,
    });
};

const removeThisProgress = data => dispatch => {
  dispatch({
    type: 'REMOVE_THIS_PROGRESS',
    payload: data,
  });
};

const setAllCourses = data => dispatch => {
    dispatch({
      type: 'SET_ALL_COURSES',
      payload: data,
    });
};

const loadingActions = bindActionCreators(
    {
        loginUser,
        logoutUser,
        setListOfTopics,
        getTopicDetails,
        setAllTopicsHierarchy,
        setAllBookmarks,
        removeThisBookmarks,
        setAllProgress,
        removeThisProgress,
        setAllSubjectsHierarchy,
        setAllCourses
    },
    store.dispatch,
  );
  
  export default loadingActions;