import CryptoJS from 'crypto-js';
import { key } from '../config/config';
// import { clearLoginCookies } from '../helpers';
// import { loadingActions } from '../redux';

export const encryption = value => CryptoJS.AES.encrypt(JSON.stringify(value), key);

export const decryption = (value) => {
  const bytes = CryptoJS.AES.decrypt((value).toString(), key);
  const defaultState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return defaultState;
};

export const storeReduxValueToLocalStore = (reducerName, defaultValue) => {
  if (localStorage.getItem(reducerName)) {
    return decryption(localStorage.getItem(reducerName));
  }
  return defaultValue;
};

export const updateLocalStorage = (defaultState, newState, name) => {
  if (JSON.stringify(defaultState) !== JSON.stringify(newState)) {
    localStorage.removeItem(name);
    localStorage.setItem(name, encryption(newState));
  }
}