import { updateLocalStorage, storeReduxValueToLocalStore } from '../../helpers';
// import { updateLocalStorage } from '../../constants/commonFunctions';

const defaultStateData = {
    topicDetails: {},
    listOfTopics: [],
    isLoggedIn: false
};

const defaultState = storeReduxValueToLocalStore('utils', defaultStateData);
const utilsReducers = (
    state= { ...defaultState },
    action,
) => {
    const newState = {...state}; //Object.assign({}, state);

    switch (action.type) {
        case 'SET_LOGIN':
            newState.userinfo = action.payload;
            newState.isLoggedIn = true;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_LOGOUT':
            newState.isLoggedIn = false;
            newState.userinfo = {};
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
            case 'SET_TOPIC_LIST_SUCCESS':
            newState.listOfTopics = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_TOPIC_HIERARCHY':
            newState.topicHierarchy = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_SUBJECT_HIERARCHY':
            newState.subjectHierarchy = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'GET_TOPIC_LIST_ERROR':
            newState.listOfTopics = []
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'GET_TOPIC_DETAILS_SUCCEESS':
            newState.topicDetails = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_ALL_BOOKMARKS':
            newState.allBookmarks = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'REMOVE_THIS_BOOKMARK':
            let id = action.payload;
            let newB = newState.allBookmarks.filter(b => b.ub_id != id );
            newState.allBookmarks = newB;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_ALL_PROGRESS':
            newState.allProgress = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'REMOVE_THIS_PROGRESS':
            let id1 = action.payload;
            let newB1 = newState.allProgress.filter(b => b.um_id != id1 );
            newState.allProgress = newB1;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        case 'SET_ALL_COURSES':
            newState.allCourses = action.payload;
            updateLocalStorage(defaultState, newState, 'utils');
            return newState;
        default:
            return newState;
    }
}

export default utilsReducers;