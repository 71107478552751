import { GetRequest, PostRequest } from "../api/axiosList";
import { apiBaseURL } from "../config/config";

export const login = (data) => {
  const URL = `${apiBaseURL}/login`;
  const header = {
    accept: 'application/json',
  };
  return PostRequest(URL, header, data);
};

export const register = (data) => {
  const URL = `${apiBaseURL}/user`;
  const header = {
    accept: 'application/json',
  };
  return PostRequest(URL, header, data);
};

export const getTopicList = () => {
  const URL = `${apiBaseURL}/btopics`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getAllTopics = () => {
  const URL = `${apiBaseURL}/maintopics`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getAllSubjects = () => {
  const URL = `${apiBaseURL}/subjects`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getAllBookmarks = (uid) => {
  const URL = `${apiBaseURL}/bookmarks?i=${uid}`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const removeThisBookmarks = (uid) => {
  const URL = `${apiBaseURL}/bookmarks?d=${uid}`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getAllProgress = (uid) => {
  const URL = `${apiBaseURL}/progress?i=${uid}`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const removeThisProgress = (uid) => {
  const URL = `${apiBaseURL}/progress?d=${uid}`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getAllCourses = (uid) => {
  const URL = `${apiBaseURL}/courses?i=${uid}`;
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const getTopicById = (id, uid) => {
  let URL = `${apiBaseURL}/topic/${id}`;
  if(!!uid){
    URL = `${apiBaseURL}/topic/${id}?i=${uid}`;
  }
  const header = {
    accept: 'application/json',
  };
  return GetRequest(URL, header);
};

export const sendToMyProgressApi = (data) => {
  const URL = `${apiBaseURL}/add_to_progress`;
  const header = {
    accept: 'application/json',
  };
  return PostRequest(URL, header, data);
};

export const addToBookmarkApi = (data) => {
  const URL = `${apiBaseURL}/add_to_bookmark`;
  const header = {
    accept: 'application/json',
  };
  return PostRequest(URL, header, data);
};