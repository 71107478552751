export const SUCCESS_MESSAGE = {
    POST_SUCCESS: 'Record save successfully.',
    PUT_SUCCESS: 'Record put successfully.',
    DELETE_SUCCESS: 'Record deleted successfully.',
    POST_GETDATA_SUCCESS: 'Record fetched successfully.',
  };
  
  export const ERROR_MESSAGE = {
    POST_ERROR: 'Could not save record, Please try again later.',
    PUT_ERROR: 'Could not put record, Please try again later.',
    DELETE_ERROR: 'Could not delete record, Please try again later.',
    POST_GETDATA_ERROR: 'Could not fetch record, Please try again later.',
  };