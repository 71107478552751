import React, { Component } from "react";
import { connect } from 'react-redux';

import "./topicDetails.css";

import { getTopicById, sendToMyProgressApi, addToBookmarkApi } from '../../constants/commonFunction';


class TopicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topic_id:null,
      topicDetails: {},
      pState: [],
      bState: []
    }
  }

  componentDidMount(){
    // console.log('propsss routeChanged', this.props);
    // (this.props.data2 || {}).data1();
    const {userInfo} = this.props;
    // console.log('userInfo',userInfo);
    // routeChangedFunction('topicDetails');
    const url = window.location.search;
    if(url){
      const queryparams = url.split('?')[1];
      const id = queryparams.split('=')[1];
      this.setState({topic_id:id});
      if(queryparams){
        getTopicById(id, userInfo.user_id).then((result) => {
          // console.log('result.data',result.data);
          this.setState({topicDetails: result.data});
        }).catch((err) => {
          console.log('Error:',err);
        });
      }
    }
  }

  sendToMyProgress(userId, topic_id, subtopic_details){
    let { pState} = this.state;
    const data = {
      "user_id": userId,
      "topic_id":topic_id,
      "subtopic_details":subtopic_details
    };
    sendToMyProgressApi(data).then(res => {
      if(res?.data?.status == 200){
        pState = [...pState, res.data.data]
        this.setState({pState});
      }
      console.log(res);
    }).catch((e)=> console.log(e));
  }
  
  addToBookmark(userId, topic_id, subtopic_details){
      let { bState} = this.state;
      const data = {
        "user_id": userId,
        "topic_id":topic_id,
        "subtopic_details":subtopic_details
      };
      addToBookmarkApi(data).then(res => {
        if(res?.data?.status == 200){
        bState = [...bState, res.data.data]
        this.setState({bState});
      }
    }).catch((e)=> console.log(e));
  }

  render() {
    const {
      pState,
      bState,
      topic_id, 
      topicDetails : {name = '', content={}, currentaffair={}, statics={}, quotes={}, diagrams={}, videos={}, questions={}, conclusion={} } = {} 
    } = this.state;
// console.log('pState',pState)
    const { userInfo, isLoggedIn } = this.props;
    
    const subTopicDetails = [
      {title:"Content",primaryKey:"content_id", htmlId:"top", data: content},
      {title:"Current Affair", primaryKey:"currentaffair_id", htmlId:"currentaffair", data: currentaffair},
      {title:"Static", primaryKey:"static_id", htmlId:"static", data: statics},
      {title:"Quote", primaryKey:"quote_id", htmlId:"quote", data: quotes},
      {title:"Conclusion", primaryKey:"conclusion_id", htmlId:"conclusion", data: conclusion},
      {title:"Questions", primaryKey:"question_id", htmlId:"question", data: questions},
    ];
    return (
      <main className="container">
    <h1 className="margin-10">{name}</h1>
      {
        subTopicDetails.map((t,k) => {
          return t.data?.description && <div className="card card-large" id={t.htmlId} key={k}>
            <div className="flex-row flex-space-between flex-gap-8">
              <h2 className="margin-0">{t.title}</h2>
              <div className="flex" style={{alignItems: 'center'}}>
              <div dangerouslySetInnerHTML={{__html: t.data?.description}}></div>
              </div>
              <div className="width100per">
                <br />
              <button 
                onClick={() => isLoggedIn ? this.sendToMyProgress(userInfo.user_id, topic_id, {[t.primaryKey]:t.data[t.primaryKey]}) : alert('please login') } 
                className={`button button-primary button-small ${(!!t?.data?.is_added_in_progress ||
                  pState.find(a => a.k == t.primaryKey && a.v == t.data[t.primaryKey] && a.mtid == topic_id)) && 'disabled'}`}
                disabled={
                  !!t?.data?.is_added_in_progress ||
                  pState.find(a => a.k == t.primaryKey && a.v == t.data[t.primaryKey] && a.mtid == topic_id)
                }
                >Send to my progress</button>
              &nbsp;
              <button
                onClick={()=> isLoggedIn ? this.addToBookmark(userInfo.user_id, topic_id,{[t.primaryKey]:t.data[t.primaryKey]}) : alert('please login') } 
                className={`button button-secondary button-small ${(!!t?.data?.is_added_in_bookmark || 
                  bState.find(a => a.k == t.primaryKey && a.v == t.data[t.primaryKey] && a.mtid == topic_id)) && 'disabled'}`}
                disabled={
                  !!t?.data?.is_added_in_bookmark || 
                  bState.find(a => a.k == t.primaryKey && a.v == t.data[t.primaryKey] && a.mtid == topic_id)
                }
              >Add to my bookmark</button>
              <span className="float-right"><i className="icon-clock"></i>{t?.data?.minutes_to_read} min read</span>
              </div>
            </div>
          </div>
          }
        )
      }

      {(diagrams || {}).description && <div className="card card-large" id="diagrams">
        <div className="flex-row flex-space-between flex-gap-8">
          <h2 className="margin-0">Diagrams</h2>
          <div className="flex" style={{alignItems: 'center'}}>
          <div dangerouslySetInnerHTML={{__html: (diagrams || {}).description}}></div>
          </div>
        </div>
      </div>}

      {(videos || {}).description && <div className="card card-large" id="videos">
        <div className="flex-row flex-space-between flex-gap-8">
          <h2 className="margin-0">Videos</h2>
          <div className="flex" style={{alignItems: 'center'}}>
          <div dangerouslySetInnerHTML={{__html: (videos || {}).description}}></div>
          </div>
        </div>
      </div>}
  </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
});

export default connect(mapStateToProps) (TopicDetails);