import React, { Component } from "react";
import { connect } from 'react-redux';

import { getAllCourses, removeThisCourses } from '../../constants/commonFunction';
import loadingActions from "../../redux/actions";
import "./courses.css";
// import logo from '../../logo.svg';
// import SearchTopic from '../searchTopics/searchTopic';
// import Header from "../../component/header/header";
// import Footer from "../../component/footer/footer";
// import { getTopicList } from "../../constants/commonFunction";

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // topicList: [],
    };
  }

  componentDidMount() {
    const { userInfo } = this.props;
    console.log('userInfo:', userInfo);
    getAllCourses(userInfo.user_id).then((response) => {   
      console.log(response); 
      loadingActions.setAllCourses(response.data);
    })
    .catch((error) => {
      console.log('Error:',error);
    });
  }

  // removeCourse(bid){
  //   removeThisCourses(bid).then((response) => {   
  //     // console.log(response); 
  //     loadingActions.removeThisCourses(response.data);
  //   })
  //   .catch((error) => {
  //     console.log('Error:',error);
  //   });
  // }

  // goToThis(id, key){
  //   const { history } = this.props;
  //   let hashtag = key.split('_')[0];
  //   if(hashtag == 'content'){
  //     hashtag = 'top';
  //   }
  //   history.push({pathname:'/topicDetails', search: `?id=${id}#${hashtag}` })
  // }

  render() {
    // const  { topicList } = this.state;

    const { allCourses } = this.props;
    // console.log('allCourses', allCourses);


    return (
      <main className="container flex-grow">
      <div className="container">
        <div className="flex flex-space-between flex-column-mobile flex-gap-32">
          <div>
            <h1>My Courses</h1>
            <p className="larger sub">
              {/* Lorem ipsum  */}
            </p>

          </div>

          <div style={{minWidth: '60%'}}>
              {
                allCourses.map(b => <div key={b?.uc_id} className="card card-medium">
                  {/* <h2 onClick={() => this.goToThis(b.ub_maintopic_id, b.ub_key)} className="curson-pointer">{b?.course_info?.title}</h2> */}
                  <img className="float-left margin-10 width100px" src={b?.course_info?.image} alt={b?.course_info?.title} />
                  <div>
                  <h2 className="curson-pointer">{b?.course_info?.title}</h2>
                  <div>
                    <strong>Subjects:</strong> {
                      b?.course_info?.subject_info?.map(s => <React.Fragment key={s?.subject_id}>
                        <span>{s.name}</span><sub>({s.exam_name})</sub>,
                        </React.Fragment>)
                    }
                  </div>
                  <div>
                    <strong>Payment paid:</strong> <span>{b.paid}</span> | 
                    <strong>Paid on:</strong> <span>{b.payment_date}</span> | 
                    <strong>Status:</strong> <span className={`${b.status == 'active' ? 'green' : 'red'}`}>{b.status}</span>
                  </div>
                  </div>
                </div>)
              }
          </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoggedIn: state.utilsReducer.isLoggedIn,
  userInfo: state.utilsReducer.userinfo || {},
  allCourses: state.utilsReducer.allCourses || [],
});

export default connect(mapStateToProps) (Courses);
