import React, { Component } from "react";
import { connect } from 'react-redux';

import { getAllSubjects } from '../../constants/commonFunction';
import loadingActions from "../../redux/actions";

import "./syllabus.css";

class Syllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // topicList: [],
    };
  }

  componentDidMount() {
    getAllSubjects().then((response) => {    
      loadingActions.setAllSubjectsHierarchy(response.data);
    })
    .catch((error) => {
      console.log('Error:',error);
    });
  }


//   nextLevel(ab){
    
// console.log(ab);
// console.log('------------');
//     let a = '';
//     ab.map(c => {
//       if(c.parent_topic_id == 0){
//         a += '<h1>'+c.name+'</h1>';
//       }
//       if(!!c.children){
//         a += '<p>'+c.name+'</p>';
//         this.nextLevel(c.children);
//       }
//     });
    
//     console.log('a',a);
//     // return a;
//   }

  render() {
    // const  { topicList } = this.state;
    const  { match, history, subjectHierarchy } = this.props;
// console.log(this.props);
// console.log(match?.params?.ename);
    // const { subjectHierarchy } = this.props;
    // console.log('sHierarchy',subjectHierarchy);
    // let subjectNames = Object.keys(subjectHierarchy);
    const enameC = match?.params?.ename.charAt(0).toUpperCase() + match?.params?.ename.slice(1);
    const subjects = subjectHierarchy[enameC] || [];
    console.log('subjects::',subjects);

    return (
      <main className="container flex-grow">
        <div className="flex">
          <div className="flex-three">
              {/* <div className="sub-wrapper  flex-wrap flex-space-between flex-column-mobile flex-gap-32"> */}
              <div className="flex sub-wrapper flex-wrap flex-space-between flex-column-mobile">
                  {
                    subjects.map((t,i) => 
                      <div onClick={()=>history.push(`/syllabus/${match?.params?.ename}/topics`, {subject_id: t?.subject_id, exam: enameC})} className="cursor-pointer flex flex-direction-column-reverse sub bordered large-shadow padding-5-10 bg-stripes rounded" key={i}>
                        <h2>{t.subject_name}</h2>
                      </div>)
                  }
            </div>
          </div>
          <div className="flex-one"></div>
        </div>
      </main>
    );
  }
}


const mapStateToProps = (state) => ({
  subjectHierarchy: state.utilsReducer.subjectHierarchy || [],
});

export default connect(mapStateToProps) (Syllabus);
